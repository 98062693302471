<template>
  <div class="menu" :class="[{activeColor: getIsWhiteBg},{activeWhite: !getIsWhiteBg}]">
    <div onclick="gtag('event', 'click_btn', {'btn_name': 'logo'})" class="logo" @click="logoClick" >
      <svg v-if="getSmallMenu && !getIsWhiteBg" class="menu__logo-small white" width="43" height="60"
           viewBox="0 0 43 60"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.9313 0C15.9313 0 19.7857 8.72983 15.9313 17.1551C15.7285 10.963 13.1927 9.23738 13.1927 9.23738C11.4684 20.2004 4.77394 24.0578 1.42674 32.077C-2.93478 42.5325 3.15106 54.8152 13.5984 57.962C24.0458 61.1088 35.8117 57.2514 40.0718 46.4914C49.0991 23.5502 24.8572 2.23321 15.9313 0ZM13.497 34.1072C12.1784 34.1072 11.1641 33.0921 11.1641 31.7725C11.1641 30.4529 12.1784 29.4378 13.497 29.4378C14.8156 29.4378 15.8299 30.4529 15.8299 31.7725C15.8299 32.9906 14.8156 34.1072 13.497 34.1072ZM24.8572 52.988C24.6543 53.1911 24.4515 53.2926 24.2486 53.2926C23.7415 53.3941 22.7272 53.5971 21.0028 53.5971C19.2785 53.5971 18.2642 53.3941 17.7571 53.2926C17.5542 53.1911 17.3513 53.0895 17.1485 52.988C14.7141 50.4503 14.3084 45.8824 14.3084 44.8673C14.3084 40.9084 17.2499 37.6601 21.0028 37.6601C24.6543 37.6601 27.6973 40.9084 27.6973 44.8673C27.5958 45.8824 27.1901 50.4503 24.8572 52.988ZM28.4073 34.1072C27.0887 34.1072 26.0744 33.0921 26.0744 31.7725C26.0744 30.4529 27.0887 29.4378 28.4073 29.4378C29.7259 29.4378 30.7402 30.4529 30.7402 31.7725C30.6388 32.9906 29.6244 34.1072 28.4073 34.1072Z"
            fill="white"/>
      </svg>
      <svg v-if="!getSmallMenu && !getIsWhiteBg" class="menu__logo white" viewBox="0 0 218 120" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
            d="M68.2142 110.059C68.2142 109.146 67.6061 108.436 66.6939 108.436C65.7816 108.436 65.0721 108.74 64.5653 109.248C64.6667 109.451 64.6667 109.755 64.6667 110.059V115.435H63.349V110.059C63.349 109.146 62.7409 108.436 61.93 108.436C61.1192 108.436 60.3083 108.74 59.9029 109.248V115.435H58.5852V107.422H59.9029V108.132C60.511 107.625 61.3219 107.32 62.2341 107.32C63.1463 107.32 63.8558 107.726 64.2613 108.436C64.9708 107.726 65.9843 107.32 67.0993 107.32C68.6196 107.32 69.6332 108.436 69.6332 110.059V115.435H68.3156V110.059H68.2142Z"
            fill="white"/>
        <path
            d="M78.1471 115.537C78.0457 115.638 77.843 115.638 77.6403 115.638C76.9308 115.638 76.5253 115.334 76.3226 114.827C75.7145 115.435 74.9036 115.74 73.9914 115.74C72.3697 115.74 71.6602 114.624 71.6602 113.508C71.6602 111.885 73.3832 110.871 75.5118 110.871C75.8158 110.871 76.0186 110.871 76.1199 110.972V109.958C76.1199 109.248 75.6131 108.436 74.4982 108.436C73.89 108.436 73.0792 108.74 72.7751 109.146L72.0656 108.436C72.6737 107.828 73.586 107.422 74.5995 107.422C76.5253 107.422 77.4376 108.639 77.4376 109.958V114.015C77.4376 114.421 77.7416 114.725 78.1471 114.725V115.537ZM76.1199 113.812V111.784C76.0186 111.784 75.8158 111.784 75.6131 111.784C74.0928 111.784 72.9778 112.392 72.9778 113.407C72.9778 113.914 73.2819 114.624 74.3968 114.624C75.005 114.522 75.7145 114.319 76.1199 113.812Z"
            fill="white"/>
        <path
            d="M80.9849 115.435C80.2754 115.131 79.9713 114.624 79.9713 114.117C79.9713 113.508 80.3767 113.001 81.0862 112.697C80.174 112.291 79.6672 111.378 79.6672 110.262C79.6672 108.436 80.9849 107.32 82.8093 107.32C83.7215 107.32 84.4311 107.625 85.0392 108.03C85.4446 107.625 85.8501 107.32 86.4582 107.32C86.6609 107.32 86.7623 107.32 86.8636 107.32V108.335C86.7623 108.335 86.5596 108.233 86.4582 108.233C86.1541 108.233 85.8501 108.436 85.6474 108.639C85.8501 109.045 86.0528 109.552 86.0528 110.059C86.0528 111.784 84.7351 113.001 82.8093 113.001C81.7957 113.001 81.1876 113.407 81.1876 113.914C81.1876 114.421 81.8971 114.827 83.1134 114.827C85.4446 114.827 86.8636 115.841 86.8636 117.261C86.8636 118.884 85.1406 120 82.708 120C80.4781 120 79.0591 118.986 79.0591 117.464C79.1604 116.551 79.9713 115.74 80.9849 115.435ZM82.8093 119.087C84.3297 119.087 85.546 118.377 85.546 117.464C85.546 116.551 84.5324 116.044 83.1134 116.044C81.4917 116.044 80.4781 116.754 80.4781 117.667C80.5795 118.478 81.3903 119.087 82.8093 119.087ZM82.9107 112.088C84.0256 112.088 84.8365 111.276 84.8365 110.262C84.8365 109.146 84.0256 108.436 82.9107 108.436C81.7958 108.436 80.9849 109.248 80.9849 110.262C80.9849 111.276 81.7958 112.088 82.9107 112.088Z"
            fill="white"/>
        <path
            d="M89.8033 104.176C90.3101 104.176 90.7156 104.582 90.7156 105.089C90.7156 105.596 90.3101 106.002 89.8033 106.002C89.2965 106.002 88.8911 105.596 88.8911 105.089C88.8911 104.582 89.2965 104.176 89.8033 104.176ZM89.1952 107.422H90.5128V115.435H89.1952V107.422Z"
            fill="white"/>
        <path
            d="M96.7968 114.421C97.709 114.421 98.4185 114.117 98.9253 113.609L99.7362 114.421C99.0267 115.232 98.0131 115.638 96.7968 115.638C94.3642 115.638 92.6411 113.914 92.6411 111.479C92.6411 109.045 94.2628 107.32 96.7968 107.32C98.0131 107.32 99.0267 107.726 99.7362 108.538L98.9253 109.349C98.4185 108.842 97.709 108.538 96.7968 108.538C95.0737 108.538 93.9588 109.755 93.9588 111.479C93.9588 113.204 95.0737 114.421 96.7968 114.421Z"
            fill="white"/>
        <path
            d="M105.007 107.422H106.021V105.596H107.338V107.422H109.467V108.436H107.338V113.001C107.338 113.914 107.946 114.522 108.757 114.522C109.061 114.522 109.264 114.421 109.467 114.319V115.435C109.163 115.537 108.859 115.638 108.555 115.638C106.933 115.638 106.021 114.624 106.021 113.001V108.436H105.007V107.422Z"
            fill="white"/>
        <path
            d="M117.474 115.537C117.372 115.638 117.17 115.638 116.967 115.638C116.257 115.638 115.852 115.334 115.649 114.827C115.041 115.435 114.23 115.74 113.318 115.74C111.696 115.74 110.987 114.624 110.987 113.508C110.987 111.885 112.71 110.871 114.838 110.871C115.142 110.871 115.345 110.871 115.447 110.972V109.958C115.447 109.248 114.94 108.436 113.825 108.436C113.217 108.436 112.406 108.74 112.102 109.146L111.392 108.436C112 107.828 112.913 107.422 113.926 107.422C115.852 107.422 116.764 108.639 116.764 109.958V114.015C116.764 114.421 117.068 114.725 117.474 114.725V115.537ZM115.447 113.812V111.784C115.345 111.784 115.142 111.784 114.94 111.784C113.419 111.784 112.304 112.392 112.304 113.407C112.304 113.914 112.609 114.624 113.723 114.624C114.23 114.522 115.041 114.319 115.447 113.812Z"
            fill="white"/>
        <path
            d="M121.934 115.537C121.832 115.638 121.528 115.638 121.326 115.638C120.312 115.638 119.603 114.928 119.603 113.812V102.147H120.92V113.711C120.92 114.32 121.326 114.624 121.934 114.624V115.537Z"
            fill="white"/>
        <path
            d="M123.961 115.537V102.147H125.278V111.175L128.522 107.523H130.042L126.697 111.276L130.448 115.537H128.927L125.278 111.378V115.537H123.961Z"
            fill="white"/>
        <path
            d="M132.982 104.176C133.489 104.176 133.894 104.582 133.894 105.089C133.894 105.596 133.489 106.002 132.982 106.002C132.475 106.002 132.07 105.596 132.07 105.089C132.07 104.582 132.475 104.176 132.982 104.176ZM132.374 107.422H133.692V115.435H132.374V107.422Z"
            fill="white"/>
        <path
            d="M137.543 107.422V108.132C138.151 107.625 138.962 107.32 139.874 107.32C141.699 107.32 142.915 108.639 142.915 110.566V115.435H141.597V110.566C141.597 109.349 140.786 108.436 139.57 108.436C138.759 108.436 137.948 108.74 137.543 109.248V115.435H136.225V107.422H137.543Z"
            fill="white"/>
        <path
            d="M146.564 115.435C145.854 115.131 145.55 114.624 145.55 114.117C145.55 113.508 145.956 113.001 146.665 112.697C145.753 112.291 145.246 111.378 145.246 110.262C145.246 108.436 146.564 107.32 148.388 107.32C149.301 107.32 150.01 107.625 150.618 108.03C151.024 107.625 151.429 107.32 152.037 107.32C152.24 107.32 152.341 107.32 152.443 107.32V108.335C152.341 108.335 152.139 108.233 152.037 108.233C151.733 108.233 151.429 108.436 151.226 108.639C151.429 109.045 151.632 109.552 151.632 110.059C151.632 111.784 150.314 113.001 148.388 113.001C147.375 113.001 146.767 113.407 146.767 113.914C146.767 114.421 147.476 114.827 148.693 114.827C151.024 114.827 152.443 115.841 152.443 117.261C152.443 118.884 150.72 120 148.287 120C146.057 120 144.638 118.986 144.638 117.464C144.74 116.551 145.449 115.74 146.564 115.435ZM148.388 119.087C149.909 119.087 151.125 118.377 151.125 117.464C151.125 116.551 150.112 116.044 148.693 116.044C147.071 116.044 146.057 116.754 146.057 117.667C146.057 118.478 146.868 119.087 148.388 119.087ZM148.388 112.088C149.503 112.088 150.314 111.276 150.314 110.262C150.314 109.146 149.503 108.436 148.388 108.436C147.273 108.436 146.463 109.248 146.463 110.262C146.564 111.276 147.273 112.088 148.388 112.088Z"
            fill="white"/>
        <path
            d="M158.017 107.422H159.031V105.596H160.348V107.422H162.477V108.436H160.348V113.001C160.348 113.914 160.956 114.522 161.767 114.522C162.071 114.522 162.274 114.421 162.477 114.319V115.435C162.173 115.537 161.869 115.638 161.565 115.638C159.943 115.638 159.031 114.624 159.031 113.001V108.436H158.017V107.422Z"
            fill="white"/>
        <path
            d="M165.822 102.046V108.132C166.43 107.625 167.241 107.32 168.153 107.32C169.977 107.32 171.194 108.639 171.194 110.566V115.435H169.876V110.566C169.876 109.349 169.065 108.436 167.849 108.436C167.038 108.436 166.227 108.74 165.822 109.248V115.435H164.504V102.046H165.822Z"
            fill="white"/>
        <path
            d="M174.539 104.176C175.045 104.176 175.451 104.582 175.451 105.089C175.451 105.596 175.045 106.002 174.539 106.002C174.032 106.002 173.626 105.596 173.626 105.089C173.626 104.582 174.032 104.176 174.539 104.176ZM173.829 107.422H175.147V115.435H173.829V107.422Z"
            fill="white"/>
        <path
            d="M179.099 107.422V108.132C179.708 107.625 180.518 107.32 181.431 107.32C183.255 107.32 184.471 108.639 184.471 110.566V115.435H183.154V110.566C183.154 109.349 182.343 108.436 181.127 108.436C180.316 108.436 179.505 108.74 179.099 109.248V115.435H177.782V107.422H179.099Z"
            fill="white"/>
        <path
            d="M188.019 115.435C187.31 115.131 187.005 114.624 187.005 114.117C187.005 113.508 187.411 113.001 188.12 112.697C187.208 112.291 186.701 111.378 186.701 110.262C186.701 108.436 188.019 107.32 189.844 107.32C190.756 107.32 191.465 107.625 192.073 108.03C192.479 107.625 192.884 107.32 193.492 107.32C193.695 107.32 193.796 107.32 193.898 107.32V108.335C193.796 108.335 193.594 108.233 193.492 108.233C193.188 108.233 192.884 108.436 192.682 108.639C192.884 109.045 193.087 109.552 193.087 110.059C193.087 111.784 191.769 113.001 189.844 113.001C188.83 113.001 188.222 113.407 188.222 113.914C188.222 114.421 188.931 114.827 190.148 114.827C192.479 114.827 193.898 115.841 193.898 117.261C193.898 118.884 192.175 120 189.742 120C187.512 120 186.093 118.986 186.093 117.464C186.195 116.551 186.904 115.74 188.019 115.435ZM189.844 119.087C191.364 119.087 192.58 118.377 192.58 117.464C192.58 116.551 191.567 116.044 190.148 116.044C188.526 116.044 187.512 116.754 187.512 117.667C187.512 118.478 188.424 119.087 189.844 119.087ZM189.945 112.088C191.06 112.088 191.871 111.276 191.871 110.262C191.871 109.146 191.06 108.436 189.945 108.436C188.83 108.436 188.019 109.248 188.019 110.262C188.019 111.276 188.83 112.088 189.945 112.088Z"
            fill="white"/>
        <path
            d="M199.574 109.045C199.27 108.639 198.56 108.436 198.054 108.436C197.141 108.436 196.837 109.045 196.837 109.552C196.837 111.276 200.588 110.668 200.588 113.204C200.588 114.421 199.777 115.638 197.952 115.638C196.939 115.638 195.925 115.232 195.317 114.522L196.128 113.711C196.533 114.218 197.243 114.522 197.851 114.522C198.966 114.522 199.27 113.711 199.27 113.204C199.27 111.581 195.52 112.189 195.52 109.552C195.52 108.436 196.229 107.219 197.952 107.219C198.864 107.219 199.777 107.523 200.385 108.132L199.574 109.045Z"
            fill="white"/>
        <path
            d="M184.168 60.2536C181.938 59.0364 179.809 57.4134 178.086 55.4861L169.674 71.0059V53.153L161.666 53.7616V94.1336H169.674V81.1496L172.816 75.7735L179.911 94.8436L188.019 93.0177L179.1 70.093L184.168 60.2536Z"
            fill="white"/>
        <path
            d="M196.736 63.1953C195.418 63.1953 194.1 63.0938 192.783 62.891V94.4379L200.891 93.7278V62.891C199.472 63.0938 198.155 63.1953 196.736 63.1953Z"
            fill="white"/>
        <path
            d="M4.96654 92.1048C2.73667 90.2789 1.01358 87.8444 0 84.6999L6.79099 81.5553L7.39913 82.6711C8.41271 84.497 9.4263 85.9171 10.4399 86.9315C11.4535 87.9459 12.5684 88.453 14.0888 88.453C15.001 88.453 15.9132 88.0473 16.6227 87.1344C17.3322 86.2214 17.7377 85.2071 17.7377 84.0912C17.7377 83.0769 17.4336 82.1639 16.9268 81.3524C16.42 80.5409 15.8118 79.8309 15.001 79.3237L7.80457 75.0634C5.77741 73.9475 4.05432 72.426 2.83802 70.6001C1.62173 68.7743 1.01358 66.7455 1.01358 64.5139C1.01358 62.1809 1.52037 60.0507 2.63531 58.2248C3.75025 56.3989 5.27062 54.9788 7.19642 53.863C9.12222 52.8486 11.2507 52.3415 13.6833 52.3415C16.5214 52.3415 18.8526 53.0515 20.8797 54.3702C22.8056 55.7903 24.3259 57.8191 25.2381 60.5579L18.4472 63.601C18.3458 63.2967 18.0417 62.688 17.5349 61.6737C17.0281 60.6593 16.5214 59.9492 15.8118 59.4421C15.1023 59.0363 14.2915 58.732 13.2779 58.732C12.0616 58.732 11.1494 59.1377 10.5412 59.8478C9.93308 60.6593 9.62901 61.5722 9.62901 62.7895C9.62901 63.7024 9.83173 64.4125 10.3385 65.1225C10.8453 65.8326 11.4535 66.3398 12.163 66.7455L18.7512 70.6001C23.6164 73.3389 26.1504 76.9907 26.4544 81.5553C26.4544 84.1927 25.8463 86.4243 24.7314 88.453C23.6164 90.4818 21.9947 92.0033 20.0689 93.1191C18.0417 94.235 15.9132 94.7421 13.4806 94.7421C10.0344 94.8436 7.29778 93.9306 4.96654 92.1048Z"
            fill="white"/>
        <path
            d="M29.6978 53.153H34.867C39.0227 53.153 42.2661 53.3559 44.6987 53.6602C47.1313 53.9645 49.2599 55.0803 51.1857 56.9062C53.0101 58.732 54.0237 61.5723 54.0237 65.5283C54.0237 69.0786 53.2128 71.8174 51.6924 73.6433C50.0707 75.4692 48.1449 76.6864 45.8137 77.295C43.4824 77.9036 40.8471 78.1065 37.8064 78.1065V93.9307L29.7991 94.5393V53.153H29.6978ZM39.124 71.5131C41.2526 71.5131 42.8743 71.1074 44.0906 70.1944C45.3069 69.2815 45.915 67.7599 45.915 65.4269C45.915 63.0938 45.3069 61.5723 44.1919 60.7608C43.077 59.9493 41.2526 59.5435 38.82 59.5435H37.705V71.5131H39.124Z"
            fill="white"/>
        <path
            d="M56.7603 94.1336V53.153H77.5386L76.9305 59.5435H64.7675V69.2815H73.383L73.2816 76.1792H64.6662V87.743H77.8427L77.2346 94.1336H56.7603Z"
            fill="white"/>
        <path
            d="M81.3904 94.1336V53.153H102.169L101.561 59.5435H89.3977V69.2815H98.0131L97.9117 76.1792H89.2963V87.743H102.473L101.865 94.1336H81.3904Z"
            fill="white"/>
        <path
            d="M106.122 88.453C104.196 84.1927 103.284 78.918 103.284 72.7303C103.284 69.3829 103.69 66.1369 104.602 63.0938C105.514 59.9492 106.933 57.4133 108.859 55.3846C110.785 53.3558 113.319 52.3415 116.258 52.3415C118.589 52.3415 120.515 53.0515 122.339 54.4716C124.063 55.8918 125.38 57.7176 126.292 60.0507C127.205 62.2823 127.711 64.7168 127.711 67.1513L120.008 67.557C120.008 65.934 119.704 64.0067 119.197 61.978C118.691 59.8478 117.576 58.8334 115.954 58.8334C114.231 58.8334 113.015 60.1521 112.406 62.8909C111.798 65.6297 111.393 68.8757 111.393 72.7303C111.393 77.4978 111.798 81.251 112.508 84.0912C113.217 86.9315 114.636 88.3516 116.663 88.3516C118.082 88.3516 119.197 87.4387 119.806 85.7142C120.414 83.9898 120.819 81.8596 121.022 79.4251L127.711 80.1352C127.711 82.7726 127.306 85.2071 126.394 87.4387C125.482 89.6703 124.164 91.4961 122.441 92.8148C120.718 94.1335 118.589 94.8436 116.055 94.8436C111.393 94.8436 108.048 92.7134 106.122 88.453Z"
            fill="white"/>
        <path
            d="M131.867 94.4379V53.7616L139.874 53.153V69.7887H147.476V53.7616L155.483 53.153V93.8293L147.476 94.4379V77.0921H139.874V93.8293L131.867 94.4379Z"
            fill="white"/>
        <path
            d="M191.769 0C191.769 0 195.621 8.72358 191.769 17.1429C191.567 10.9552 189.033 9.23077 189.033 9.23077C187.31 20.186 180.62 24.0406 177.275 32.0541C172.917 42.5021 178.998 54.776 189.438 57.9205C199.878 61.0651 211.635 57.2105 215.892 46.4581C224.913 23.5334 200.689 2.23161 191.769 0ZM189.337 34.0828C188.019 34.0828 187.005 33.0685 187.005 31.7498C187.005 30.4311 188.019 29.4167 189.337 29.4167C190.654 29.4167 191.668 30.4311 191.668 31.7498C191.668 32.967 190.654 34.0828 189.337 34.0828ZM200.689 52.9501C200.486 53.153 200.283 53.2544 200.081 53.2544C199.574 53.3559 198.56 53.5587 196.837 53.5587C195.114 53.5587 194.101 53.3559 193.594 53.2544C193.391 53.153 193.188 53.0515 192.986 52.9501C190.553 50.4142 190.148 45.8495 190.148 44.8352C190.148 40.8791 193.087 37.6331 196.837 37.6331C200.486 37.6331 203.527 40.8791 203.527 44.8352C203.425 45.8495 203.02 50.4142 200.689 52.9501ZM204.236 34.0828C202.919 34.0828 201.905 33.0685 201.905 31.7498C201.905 30.4311 202.919 29.4167 204.236 29.4167C205.554 29.4167 206.568 30.4311 206.568 31.7498C206.466 32.967 205.453 34.0828 204.236 34.0828Z"
            fill="white"/>
      </svg>

      <svg v-if="getSmallMenu && getIsWhiteBg" class="menu__logo-small color" width="43" height="59" viewBox="0 0 43 59"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.44925 31.9679C4.84429 23.9893 11.4727 20.1874 13.251 9.21017C13.251 9.21017 15.8377 10.9772 16.0533 17.1352C19.8794 8.72825 15.9994 0 15.9994 0C24.8912 2.249 49.357 23.5074 40.3035 46.2651C36.0463 57.0817 24.2445 60.8835 13.6821 57.7242C3.17371 54.6185 -2.96968 42.4096 1.44925 31.9679Z"
            fill="url(#paint0_linear_288_810)"/>
        <path
            d="M17.077 52.905C17.2387 53.0656 17.4542 53.1727 17.6698 53.2263C18.1548 53.3334 19.1787 53.494 20.957 53.494C22.7354 53.494 23.7593 53.3334 24.2443 53.2263C24.4599 53.1727 24.6754 53.0656 24.8371 52.905C27.2621 50.3883 27.6394 45.7832 27.6394 44.7658C27.6394 40.8033 24.6215 37.5369 20.9032 37.5369C17.1848 37.5369 14.167 40.7497 14.167 44.7658C14.2748 45.7832 14.652 50.3883 17.077 52.905Z"
            fill="white"/>
        <path
            d="M13.5204 34.0563C14.8001 34.0563 15.8376 33.0254 15.8376 31.7537C15.8376 30.4821 14.8001 29.4512 13.5204 29.4512C12.2406 29.4512 11.2031 30.4821 11.2031 31.7537C11.2031 33.0254 12.2406 34.0563 13.5204 34.0563Z"
            fill="white"/>
        <path
            d="M28.4481 34.0562C29.7279 34.0562 30.7654 33.0253 30.7654 31.7537C30.7654 30.482 29.7279 29.4511 28.4481 29.4511C27.1683 29.4511 26.1309 30.482 26.1309 31.7537C26.1309 33.0253 27.1683 34.0562 28.4481 34.0562Z"
            fill="white"/>
        <defs>
          <linearGradient id="paint0_linear_288_810" x1="10.5435" y1="56.51" x2="35.0629" y2="9.64527"
                          gradientUnits="userSpaceOnUse">
            <stop stop-color="#E63312"/>
            <stop offset="0.00546843" stop-color="#E53314"/>
            <stop offset="0.2261" stop-color="#C13042"/>
            <stop offset="0.4327" stop-color="#A02D5D"/>
            <stop offset="0.6191" stop-color="#832A70"/>
            <stop offset="0.7817" stop-color="#6E277B"/>
            <stop offset="0.9142" stop-color="#612581"/>
            <stop offset="1" stop-color="#5C2483"/>
          </linearGradient>
        </defs>
      </svg>
      <svg v-if="!getSmallMenu && getIsWhiteBg" class="menu__logo color" viewBox="0 0 219 120" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
            d="M67.6313 109.933C67.6313 109.023 67.0385 108.327 66.1223 108.327C65.2062 108.327 64.5057 108.594 63.9668 109.13C64.0207 109.398 64.0745 109.665 64.0745 109.933V115.341H62.7812V109.933C62.7812 109.023 62.1884 108.327 61.3262 108.327C60.464 108.327 59.7634 108.648 59.2245 109.13V115.341H57.9312V107.309H59.2245V108.005C59.8712 107.47 60.6795 107.202 61.5956 107.202C62.5118 107.202 63.2662 107.631 63.6434 108.327C64.344 107.631 65.314 107.202 66.4457 107.202C67.9546 107.202 68.9246 108.327 68.9246 109.933V115.341H67.6313V109.933Z"
            fill="#4A4A49"/>
        <path
            d="M77.6009 115.341C77.4932 115.395 77.2776 115.395 77.0621 115.395C76.4154 115.395 75.9304 115.074 75.7148 114.538C75.0681 115.127 74.2598 115.449 73.3976 115.449C71.7809 115.449 71.0803 114.324 71.0803 113.253C71.0803 111.593 72.8048 110.629 74.9604 110.629C75.2837 110.629 75.4454 110.629 75.5531 110.683V109.719C75.5531 109.023 75.0681 108.22 73.9365 108.22C73.2898 108.22 72.5353 108.487 72.1581 108.862L71.4576 108.166C72.1042 107.524 73.0203 107.149 74.0442 107.149C75.9304 107.149 76.9004 108.38 76.9004 109.719V113.789C76.9004 114.217 77.1698 114.431 77.6009 114.431V115.341ZM75.607 113.628V111.593C75.4993 111.593 75.3376 111.54 75.0681 111.54C73.5592 111.54 72.4276 112.129 72.4276 113.092C72.4276 113.574 72.7509 114.324 73.8287 114.324C74.4215 114.431 75.122 114.163 75.607 113.628Z"
            fill="#4A4A49"/>
        <path
            d="M80.511 115.288C79.8104 114.967 79.4871 114.538 79.4871 113.949C79.4871 113.36 79.8643 112.878 80.6188 112.557C79.7026 112.129 79.2176 111.218 79.2176 110.147C79.2176 108.38 80.511 107.202 82.3971 107.202C83.3132 107.202 84.0677 107.47 84.6066 107.898C84.9838 107.47 85.4688 107.202 86.0077 107.202C86.1694 107.202 86.331 107.202 86.4388 107.256V108.327C86.331 108.273 86.1694 108.273 86.0616 108.273C85.7383 108.273 85.4688 108.434 85.2533 108.701C85.4688 109.13 85.6305 109.612 85.6305 110.147C85.6305 111.861 84.3371 113.039 82.3971 113.039C81.3732 113.039 80.7804 113.414 80.7804 113.949C80.7804 114.485 81.5349 114.859 82.7205 114.859C85.0377 114.859 86.5466 115.823 86.5466 117.323C86.5466 118.929 84.7682 120 82.3971 120C80.1338 120 78.7326 118.983 78.7326 117.537C78.6787 116.412 79.3793 115.663 80.511 115.288ZM82.3432 118.929C83.906 118.929 85.0916 118.233 85.0916 117.269C85.0916 116.412 84.1216 115.823 82.6666 115.823C81.0499 115.823 80.026 116.519 80.026 117.483C80.026 118.34 80.8882 118.929 82.3432 118.929ZM82.3971 111.914C83.5288 111.914 84.2832 111.165 84.2832 110.04C84.2832 108.969 83.5288 108.166 82.3971 108.166C81.2654 108.166 80.511 108.916 80.511 110.04C80.511 111.165 81.2654 111.914 82.3971 111.914Z"
            fill="#4A4A49"/>
        <path
            d="M89.3486 104.043C89.8336 104.043 90.2108 104.418 90.2108 104.9C90.2108 105.382 89.8336 105.756 89.3486 105.756C88.8636 105.756 88.4863 105.382 88.4863 104.9C88.4863 104.418 88.8636 104.043 89.3486 104.043ZM88.7019 107.309H89.9952V115.341H88.7019V107.309Z"
            fill="#4A4A49"/>
        <path
            d="M96.3545 114.324C97.2167 114.324 97.9712 114.003 98.4562 113.467L99.2645 114.27C98.564 115.02 97.4862 115.448 96.3006 115.448C93.8217 115.448 92.1511 113.788 92.1511 111.325C92.1511 108.862 93.8217 107.202 96.3006 107.202C97.5401 107.202 98.564 107.631 99.2645 108.434L98.4562 109.237C97.9712 108.701 97.2167 108.38 96.3545 108.38C94.63 108.38 93.4984 109.612 93.4984 111.379C93.4984 113.092 94.63 114.324 96.3545 114.324Z"
            fill="#4A4A49"/>
        <path
            d="M104.6 107.309H105.624V105.542H106.971V107.309H109.126V108.327H106.971V112.825C106.971 113.681 107.564 114.324 108.426 114.324C108.695 114.324 108.965 114.27 109.18 114.163V115.288C108.911 115.395 108.534 115.449 108.21 115.449C106.647 115.449 105.624 114.431 105.624 112.825V108.327H104.6V107.309Z"
            fill="#4A4A49"/>
        <path
            d="M117.156 115.341C117.048 115.395 116.833 115.395 116.617 115.395C115.97 115.395 115.485 115.074 115.27 114.538C114.623 115.127 113.815 115.448 112.953 115.448C111.336 115.448 110.635 114.324 110.635 113.253C110.635 111.593 112.36 110.629 114.515 110.629C114.839 110.629 115 110.629 115.108 110.683V109.719C115.108 109.023 114.623 108.22 113.491 108.22C112.845 108.22 112.09 108.487 111.713 108.862L111.012 108.22C111.659 107.577 112.575 107.202 113.599 107.202C115.485 107.202 116.455 108.434 116.455 109.772V113.842C116.455 114.27 116.725 114.485 117.156 114.485V115.341ZM115.162 113.628V111.593C115.054 111.593 114.893 111.539 114.623 111.539C113.114 111.539 111.982 112.129 111.982 113.092C111.982 113.574 112.306 114.324 113.384 114.324C113.923 114.431 114.677 114.163 115.162 113.628Z"
            fill="#4A4A49"/>
        <path
            d="M121.628 115.341C121.521 115.395 121.197 115.395 120.982 115.395C119.958 115.395 119.257 114.699 119.257 113.521V101.901H120.551V113.467C120.551 114.11 120.982 114.431 121.575 114.431V115.341H121.628Z"
            fill="#4A4A49"/>
        <path
            d="M123.73 115.341V101.955H125.024V110.951L128.257 107.309H129.82L126.425 111.058L130.197 115.341H128.634L125.024 111.218V115.341H123.73Z"
            fill="#4A4A49"/>
        <path
            d="M132.73 104.043C133.215 104.043 133.592 104.418 133.592 104.9C133.592 105.382 133.215 105.756 132.73 105.756C132.245 105.756 131.867 105.382 131.867 104.9C131.867 104.418 132.245 104.043 132.73 104.043ZM132.083 107.309H133.376V115.341H132.083V107.309Z"
            fill="#4A4A49"/>
        <path
            d="M137.364 107.309V108.005C138.011 107.47 138.819 107.202 139.735 107.202C141.567 107.202 142.753 108.541 142.753 110.469V115.341H141.46V110.469C141.46 109.237 140.651 108.327 139.412 108.327C138.55 108.327 137.849 108.648 137.31 109.13V115.341H136.017V107.309H137.364Z"
            fill="#4A4A49"/>
        <path
            d="M146.364 115.288C145.663 114.967 145.34 114.538 145.34 113.949C145.34 113.36 145.717 112.878 146.472 112.557C145.555 112.129 145.07 111.218 145.07 110.147C145.07 108.38 146.364 107.202 148.25 107.202C149.166 107.202 149.92 107.47 150.459 107.898C150.837 107.47 151.322 107.202 151.86 107.202C152.022 107.202 152.184 107.202 152.292 107.256V108.327C152.184 108.273 152.022 108.273 151.914 108.273C151.591 108.273 151.322 108.434 151.106 108.701C151.322 109.13 151.483 109.612 151.483 110.147C151.483 111.861 150.19 113.039 148.25 113.039C147.226 113.039 146.633 113.414 146.633 113.949C146.633 114.485 147.388 114.859 148.573 114.859C150.89 114.859 152.399 115.823 152.399 117.323C152.399 118.929 150.621 120 148.25 120C145.987 120 144.585 118.983 144.585 117.537C144.532 116.412 145.232 115.663 146.364 115.288ZM148.196 118.929C149.759 118.929 150.944 118.233 150.944 117.269C150.944 116.412 149.974 115.823 148.519 115.823C146.903 115.823 145.879 116.519 145.879 117.483C145.879 118.34 146.741 118.929 148.196 118.929ZM148.25 111.914C149.382 111.914 150.136 111.165 150.136 110.04C150.136 108.969 149.382 108.166 148.25 108.166C147.118 108.166 146.364 108.916 146.364 110.04C146.364 111.165 147.118 111.914 148.25 111.914Z"
            fill="#4A4A49"/>
        <path
            d="M157.896 107.309H158.92V105.542H160.267V107.309H162.423V108.327H160.267V112.825C160.267 113.681 160.86 114.324 161.722 114.324C161.992 114.324 162.261 114.27 162.477 114.163V115.288C162.207 115.395 161.83 115.449 161.507 115.449C159.944 115.449 158.92 114.431 158.92 112.825V108.327H157.896V107.309Z"
            fill="#4A4A49"/>
        <path
            d="M165.71 101.955V108.005C166.357 107.47 167.165 107.202 168.081 107.202C169.913 107.202 171.099 108.541 171.099 110.469V115.341H169.806V110.469C169.806 109.237 168.997 108.327 167.758 108.327C166.896 108.327 166.195 108.648 165.656 109.13V115.341H164.363V101.955H165.71Z"
            fill="#4A4A49"/>
        <path
            d="M174.494 104.043C174.979 104.043 175.356 104.418 175.356 104.9C175.356 105.382 174.979 105.756 174.494 105.756C174.009 105.756 173.632 105.382 173.632 104.9C173.632 104.418 174.009 104.043 174.494 104.043ZM173.847 107.309H175.14V115.341H173.847V107.309Z"
            fill="#4A4A49"/>
        <path
            d="M179.075 107.309V108.005C179.721 107.47 180.53 107.202 181.446 107.202C183.278 107.202 184.464 108.541 184.464 110.469V115.341H183.224V110.469C183.224 109.237 182.416 108.327 181.176 108.327C180.314 108.327 179.613 108.648 179.075 109.13V115.341H177.781V107.309H179.075Z"
            fill="#4A4A49"/>
        <path
            d="M188.128 115.288C187.427 114.967 187.104 114.538 187.104 113.949C187.104 113.36 187.481 112.878 188.236 112.557C187.32 112.129 186.835 111.218 186.835 110.147C186.835 108.38 188.128 107.202 190.014 107.202C190.93 107.202 191.685 107.47 192.224 107.898C192.601 107.47 193.086 107.202 193.625 107.202C193.786 107.202 193.948 107.202 194.056 107.256V108.327C193.948 108.273 193.786 108.273 193.679 108.273C193.355 108.273 193.086 108.434 192.87 108.701C193.086 109.13 193.247 109.612 193.247 110.147C193.247 111.861 191.954 113.039 190.014 113.039C188.99 113.039 188.397 113.414 188.397 113.949C188.397 114.485 189.152 114.859 190.337 114.859C192.655 114.859 194.164 115.823 194.164 117.323C194.164 118.929 192.385 120 190.014 120C187.751 120 186.35 118.983 186.35 117.537C186.242 116.412 186.996 115.663 188.128 115.288ZM189.906 118.929C191.469 118.929 192.655 118.233 192.655 117.269C192.655 116.412 191.685 115.823 190.23 115.823C188.613 115.823 187.589 116.519 187.589 117.483C187.589 118.34 188.451 118.929 189.906 118.929ZM190.014 111.914C191.146 111.914 191.9 111.165 191.9 110.04C191.9 108.969 191.146 108.166 190.014 108.166C188.882 108.166 188.128 108.916 188.128 110.04C188.128 111.165 188.882 111.914 190.014 111.914Z"
            fill="#4A4A49"/>
        <path
            d="M199.714 108.916C199.391 108.541 198.744 108.273 198.151 108.273C197.289 108.273 196.966 108.916 196.966 109.451C196.966 111.165 200.738 110.576 200.738 113.039C200.738 114.217 199.876 115.448 198.044 115.448C197.02 115.448 196.05 115.074 195.403 114.378L196.211 113.574C196.643 114.056 197.343 114.378 197.936 114.378C199.014 114.378 199.391 113.628 199.391 113.092C199.391 111.432 195.619 112.021 195.619 109.505C195.619 108.327 196.373 107.202 198.044 107.202C198.96 107.202 199.876 107.523 200.523 108.166L199.714 108.916Z"
            fill="#4A4A49"/>
        <path
            d="M177.35 31.9679C180.745 23.9893 187.374 20.1874 189.152 9.21017C189.152 9.21017 191.739 10.9772 191.954 17.1352C195.78 8.72825 191.9 0 191.9 0C200.792 2.249 225.258 23.5074 216.204 46.2651C211.947 57.0817 200.145 60.8835 189.583 57.7242C179.075 54.6185 172.931 42.4096 177.35 31.9679Z"
            fill="url(#paint0_linear_248_2046)"/>
        <path
            d="M184.194 60.241C181.877 59.0094 179.829 57.4029 178.051 55.5288L169.59 71.004V53.1191L161.507 53.7617V94.083H169.59V81.1245L172.716 75.7162L179.883 94.7791L188.074 92.9049L179.128 70.0402L184.194 60.241Z"
            fill="#4A4A49"/>
        <path
            d="M196.804 63.1861C195.457 63.1861 194.11 63.079 192.816 62.9183V94.4043L200.953 93.7617V62.9183C199.606 63.079 198.259 63.1861 196.804 63.1861Z"
            fill="#4A4A49"/>
        <path
            d="M5.01172 91.9946C2.74836 90.174 1.0239 87.7108 0 84.6051L6.79007 81.4458L7.43674 82.5167C8.51453 84.3373 9.53843 85.7296 10.5084 86.747C11.4785 87.7644 12.7179 88.2999 14.1729 88.2999C15.1429 88.2999 15.9513 87.8715 16.7057 86.9612C17.4602 86.0509 17.8374 85.0335 17.8374 83.9625C17.8374 82.9451 17.568 82.0348 17.083 81.178C16.5441 80.3213 15.8974 79.6787 15.089 79.1432L7.92175 74.913C5.82006 73.7349 4.14949 72.2891 2.91003 70.4685C1.67057 68.7015 1.0239 66.6667 1.0239 64.4712C1.0239 62.1151 1.56279 59.9732 2.69447 58.1526C3.82615 56.2784 5.33505 54.8862 7.27507 53.8688C9.21509 52.8514 11.3707 52.3159 13.7957 52.3159C16.5979 52.3159 19.023 53.012 20.963 54.4043C22.9569 55.7965 24.4119 57.8313 25.3819 60.5622L18.5919 63.6144C18.4302 63.3467 18.1607 62.6506 17.6757 61.6332C17.1907 60.6158 16.5979 59.8661 15.9513 59.4377C15.2507 59.0094 14.4424 58.7416 13.4724 58.7416C12.2868 58.7416 11.3168 59.1165 10.724 59.8661C10.0773 60.6158 9.75399 61.6332 9.75399 62.8112C9.75399 63.668 10.0234 64.4712 10.5084 65.1673C10.9934 65.8634 11.5862 66.4525 12.2868 66.8273L18.9152 70.6827C23.8191 73.4672 26.4058 77.1084 26.6753 81.6064C26.6753 84.1767 26.0825 86.4792 24.9508 88.4605C23.8191 90.4418 22.2024 91.9946 20.2085 93.0656C18.2146 94.1365 16.0052 94.7256 13.5262 94.7256C10.1312 94.7791 7.32896 93.8688 5.01172 91.9946Z"
            fill="#4A4A49"/>
        <path
            d="M29.9086 53.1191H35.0281C39.1776 53.1191 42.4649 53.2798 44.8899 53.6546C47.3149 53.9759 49.5244 55.0469 51.4105 56.8675C53.2966 58.6345 54.2128 61.5261 54.2128 65.4351C54.2128 69.0228 53.4044 71.7001 51.8416 73.5207C50.2249 75.3414 48.2849 76.573 45.9677 77.1084C43.6504 77.6974 40.956 77.9652 37.9382 77.9652V93.7082L29.8547 94.3507V53.1191H29.9086ZM39.3932 71.4324C41.4949 71.4324 43.1654 71.004 44.4049 70.1472C45.6443 69.2905 46.2371 67.6841 46.2371 65.328C46.2371 63.0254 45.6443 61.4726 44.5127 60.6693C43.381 59.8661 41.6026 59.4913 39.1237 59.4913H37.992V71.4324H39.3932Z"
            fill="#4A4A49"/>
        <path
            d="M57.0154 94.0294V53.1191H77.9245L77.2778 59.4913H65.0988V69.2369H73.775L73.7211 76.091H65.0988V87.6037H78.4095L77.7628 93.9759H57.0154V94.0294Z"
            fill="#4A4A49"/>
        <path
            d="M81.373 94.0294V53.1191H102.282L101.635 59.4913H89.4565V69.2369H98.1327L98.0788 76.091H89.4565V87.6037H102.767L102.12 93.9759H81.373V94.0294Z"
            fill="#4A4A49"/>
        <path
            d="M106.216 88.407C104.276 84.1767 103.36 78.9291 103.36 72.7176C103.36 69.3976 103.791 66.1312 104.653 63.079C105.516 59.9733 106.971 57.4565 108.911 55.4217C110.851 53.3869 113.33 52.3695 116.347 52.3695C118.665 52.3695 120.659 53.0656 122.437 54.5114C124.161 55.9036 125.509 57.7778 126.425 60.0268C127.341 62.2758 127.826 64.6854 127.826 67.1486L120.012 67.5235C120.012 65.8635 119.742 64.0429 119.204 61.9545C118.665 59.8662 117.533 58.7952 115.916 58.7952C114.192 58.7952 113.006 60.1339 112.36 62.8648C111.713 65.5957 111.39 68.8622 111.39 72.664C111.39 77.3762 111.767 81.1781 112.467 84.0161C113.222 86.8541 114.623 88.2999 116.671 88.2999C118.126 88.2999 119.204 87.4431 119.796 85.7296C120.443 84.0161 120.82 81.9277 120.982 79.411L127.826 80.0536C127.826 82.6774 127.395 85.087 126.533 87.336C125.67 89.585 124.323 91.3521 122.599 92.6908C120.82 94.0295 118.719 94.7256 116.186 94.7256C111.497 94.7792 108.156 92.6372 106.216 88.407Z"
            fill="#4A4A49"/>
        <path
            d="M131.652 94.3507V53.7617L139.735 53.1191V69.7189H147.441V53.8153L155.525 53.1727V93.7617L147.441 94.4043V77.0549H139.735V93.7617L131.652 94.3507Z"
            fill="#4A4A49"/>
        <path
            d="M192.978 52.905C193.14 53.0656 193.355 53.1727 193.571 53.2263C194.056 53.3334 195.08 53.494 196.858 53.494C198.636 53.494 199.66 53.3334 200.145 53.2263C200.361 53.1727 200.576 53.0656 200.738 52.905C203.163 50.3883 203.54 45.7832 203.54 44.7658C203.54 40.8033 200.522 37.5369 196.804 37.5369C193.086 37.5369 190.068 40.7497 190.068 44.7658C190.176 45.7832 190.553 50.3883 192.978 52.905Z"
            fill="white"/>
        <path
            d="M189.421 34.0563C190.701 34.0563 191.738 33.0254 191.738 31.7537C191.738 30.4821 190.701 29.4512 189.421 29.4512C188.141 29.4512 187.104 30.4821 187.104 31.7537C187.104 33.0254 188.141 34.0563 189.421 34.0563Z"
            fill="white"/>
        <path
            d="M204.349 34.0563C205.628 34.0563 206.666 33.0254 206.666 31.7537C206.666 30.4821 205.628 29.4512 204.349 29.4512C203.069 29.4512 202.031 30.4821 202.031 31.7537C202.031 33.0254 203.069 34.0563 204.349 34.0563Z"
            fill="white"/>
        <defs>
          <linearGradient id="paint0_linear_248_2046" x1="186.444" y1="56.51" x2="210.964" y2="9.64527"
                          gradientUnits="userSpaceOnUse">
            <stop stop-color="#E63312"/>
            <stop offset="0.00546843" stop-color="#E53314"/>
            <stop offset="0.2261" stop-color="#C13042"/>
            <stop offset="0.4327" stop-color="#A02D5D"/>
            <stop offset="0.6191" stop-color="#832A70"/>
            <stop offset="0.7817" stop-color="#6E277B"/>
            <stop offset="0.9142" stop-color="#612581"/>
            <stop offset="1" stop-color="#5C2483"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
    <nav class="menu__navigation desk-wrapper">

      <div class="menu__hide" :class="[{ active: !getSmallMenu }]">

        <router-link class="hover-menu" to="/"><span
            onclick="gtag('event', 'click_btn', {'btn_name': 'Home'})">Home</span></router-link>

        <router-link class="hover-menu" to="/magic"><span onclick="gtag('event', 'click_btn', {'btn_name': 'magic'})">magic</span>
        </router-link>

        <a onclick="gtag('event', 'click_btn', {'btn_name': 'Google Play'})"
           href="https://play.google.com/store/apps/details?id=com.speechki.app&hl=uk&gl=US" class="hover-menu"
           target="_blank">
          google play
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.982229 0.496033C0.703713 0.496029 0.477929 0.717944 0.477926 0.991692C0.477922 1.26544 0.703701 1.48736 0.982217 1.48736L11.7852 1.48749L0.361347 12.9624C0.166569 13.1581 0.170463 13.4715 0.370046 13.6624C0.569628 13.8533 0.889321 13.8495 1.0841 13.6539L12.481 2.20597L12.4816 13.0061C12.4817 13.2798 12.7075 13.5017 12.986 13.5017C13.2645 13.5017 13.4903 13.2797 13.4902 13.006L13.4895 0.99181L13.4895 0.496179L12.9852 0.496174L0.982229 0.496033Z"
                  fill="white" fill-opacity="0.6"/>
          </svg>
        </a>

        <a onclick="gtag('event', 'click_btn', {'btn_name': 'App Store'})"
           href="https://apps.apple.com/ua/app/speechki-ar-platform/id1434538222?l=uk" class="hover-menu"
           target="_blank">
          app store
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.982229 0.496033C0.703713 0.496029 0.477929 0.717944 0.477926 0.991692C0.477922 1.26544 0.703701 1.48736 0.982217 1.48736L11.7852 1.48749L0.361347 12.9624C0.166569 13.1581 0.170463 13.4715 0.370046 13.6624C0.569628 13.8533 0.889321 13.8495 1.0841 13.6539L12.481 2.20597L12.4816 13.0061C12.4817 13.2798 12.7075 13.5017 12.986 13.5017C13.2645 13.5017 13.4903 13.2797 13.4902 13.006L13.4895 0.99181L13.4895 0.496179L12.9852 0.496174L0.982229 0.496033Z"
                  fill="white" fill-opacity="0.6"/>
          </svg>
        </a>

      </div>

      <a href="https://inchkiev.ua/contact" class="hover-menu" target="_blank"
         onclick="gtag('event', 'click_btn', {'btn_name': 'Get in touch'})">
        Get in touch
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.982229 0.496033C0.703713 0.496029 0.477929 0.717944 0.477926 0.991692C0.477922 1.26544 0.703701 1.48736 0.982217 1.48736L11.7852 1.48749L0.361347 12.9624C0.166569 13.1581 0.170463 13.4715 0.370046 13.6624C0.569628 13.8533 0.889321 13.8495 1.0841 13.6539L12.481 2.20597L12.4816 13.0061C12.4817 13.2798 12.7075 13.5017 12.986 13.5017C13.2645 13.5017 13.4903 13.2797 13.4902 13.006L13.4895 0.99181L13.4895 0.496179L12.9852 0.496174L0.982229 0.496033Z"
                fill="white" fill-opacity="0.6"/>
        </svg>
      </a>
    </nav>

    <div class="menu__burger" @click='menuMobile(true)'>
      <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="5" y="2" width="19" height="1" fill="white"/>
        <rect y="9" width="24" height="1" fill="white"/>
        <rect x="5" y="16" width="19" height="1" fill="white"/>
      </svg>

    </div>

    <div class="menu-mobile" v-bind:class="[{ active: getMobileMenu }]">

      <svg class="menu-mobile__close" @click='menuMobile(false)' width="34" height="34" viewBox="0 0 34 34"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M8.48535 8.4852L25.4559 25.4558" stroke="white"/>
        <path d="M8.48535 25.4559L25.4559 8.48534" stroke="white"/>
      </svg>

      <nav class="menu-mobile__links">
        <router-link class="hover-menu" to="/"><span @click='menuMobile(false)'
                                                     onclick="gtag('event', 'click_btn', {'btn_name': 'Home'})">Home</span>
        </router-link>

        <router-link class="hover-menu" to="/magic"><span @click='menuMobile(false)'
                                                          onclick="gtag('event', 'click_btn', {'btn_name': 'magic'})">magic</span>
        </router-link>

        <a onclick="gtag('event', 'click_btn', {'btn_name': 'Google Play'})"
           href="https://play.google.com/store/apps/details?id=com.speechki.app&hl=uk&gl=US" @click='menuMobile(false)'
           class="hover-menu" target="_blank">
          google play
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.982229 0.496033C0.703713 0.496029 0.477929 0.717944 0.477926 0.991692C0.477922 1.26544 0.703701 1.48736 0.982217 1.48736L11.7852 1.48749L0.361347 12.9624C0.166569 13.1581 0.170463 13.4715 0.370046 13.6624C0.569628 13.8533 0.889321 13.8495 1.0841 13.6539L12.481 2.20597L12.4816 13.0061C12.4817 13.2798 12.7075 13.5017 12.986 13.5017C13.2645 13.5017 13.4903 13.2797 13.4902 13.006L13.4895 0.99181L13.4895 0.496179L12.9852 0.496174L0.982229 0.496033Z"
                  fill="white" fill-opacity="0.6"/>
          </svg>
        </a>

        <a onclick="gtag('event', 'click_btn', {'btn_name': 'App Store'})"
           href="https://apps.apple.com/ua/app/speechki-ar-platform/id1434538222?l=uk" @click='menuMobile(false)'
           class="hover-menu" target="_blank">
          app store
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.982229 0.496033C0.703713 0.496029 0.477929 0.717944 0.477926 0.991692C0.477922 1.26544 0.703701 1.48736 0.982217 1.48736L11.7852 1.48749L0.361347 12.9624C0.166569 13.1581 0.170463 13.4715 0.370046 13.6624C0.569628 13.8533 0.889321 13.8495 1.0841 13.6539L12.481 2.20597L12.4816 13.0061C12.4817 13.2798 12.7075 13.5017 12.986 13.5017C13.2645 13.5017 13.4903 13.2797 13.4902 13.006L13.4895 0.99181L13.4895 0.496179L12.9852 0.496174L0.982229 0.496033Z"
                  fill="white" fill-opacity="0.6"/>
          </svg>
        </a>

        <a href="https://inchkiev.ua/contact" @click='menuMobile(false)' class="hover-menu" target="_blank"
           onclick="gtag('event', 'click_btn', {'btn_name': 'Get in touch'})">
          Get in touch
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.982229 0.496033C0.703713 0.496029 0.477929 0.717944 0.477926 0.991692C0.477922 1.26544 0.703701 1.48736 0.982217 1.48736L11.7852 1.48749L0.361347 12.9624C0.166569 13.1581 0.170463 13.4715 0.370046 13.6624C0.569628 13.8533 0.889321 13.8495 1.0841 13.6539L12.481 2.20597L12.4816 13.0061C12.4817 13.2798 12.7075 13.5017 12.986 13.5017C13.2645 13.5017 13.4903 13.2797 13.4902 13.006L13.4895 0.99181L13.4895 0.496179L12.9852 0.496174L0.982229 0.496033Z"
                  fill="white" fill-opacity="0.6"/>
          </svg>
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "menu",
  computed: {
    ...mapGetters(['getSmallMenu', 'getMobileMenu', 'getIsWhiteBg'])
  },
  methods: {
    ...mapActions(['mobileMenuSwitch', 'goToStage']),

    logoClick() {
      this.$router.push('/')
      this.goToStage(1)
    },
    menuMobile(data) {
      this.mobileMenuSwitch(data)
    }
  }
}
</script>

<style scoped lang="scss">

@import "@/assets/style/styles";

.menu {
  z-index: 10;
  padding: 0 20px;
  height: 120px;

  .logo {
    cursor: pointer;
  }

  @media screen and (max-width: 1160px) {
    height: 30px;
  }

  &.activeWhite {

    .white {
      display: block;
    }

    .menu__navigation a {
      color: rgba(255, 255, 255, 0.6);

      &.router-link-active {
        color: white !important;
      }
    }


  }

  &.activeColor {
    .color {
      display: block;
    }

    .menu__burger {
      rect {
        fill: #8E2959;
      }
    }

    .menu__navigation a {
      color: #8E2959;

      &.router-link-active {
        color: #4A4A49;
      }

      svg {
        path {
          fill: #8E2959;
          fill-opacity: 1;
        }
      }
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1800px;
  width: 100%;

  margin: 0 auto;

  position: fixed;
  top: 28px;
  left: 0;
  right: 0;

  &__burger {
    margin-top: 10px;
    display: none;

    @media screen and (max-width: 1160px) {
      display: block;
    }

    svg {
      display: block;
    }
  }

  &__hide {
    pointer-events: none;
    opacity: 0;
    transition: all .5s ease-in-out;

    &.active {
      pointer-events: all;
      opacity: 1;
    }
  }

  &__logo {
    max-width: 217px;
    width: 100%;
    height: auto;
    display: inline-block;
    box-sizing: content-box;

    @media screen and (max-width: 1160px) {
      max-width: 108px;
    }
  }

  &__logo-small {
    width: 43px;
    height: auto;
    display: inline-block;
    box-sizing: content-box;
    @media screen and (max-width: 1160px) {
      width: 21px;
    }
  }

  &__navigation {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &.desk-wrapper {
      @media screen and (max-width: 1160px) {
        display: none;
      }
    }

    a {
      margin: 0 24px;
      text-decoration: none;

      font-family: $light;
      font-size: 18px;
      line-height: 22px;

      letter-spacing: 0.02em;
      text-transform: uppercase;

      transition: all .2s ease;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      svg {
        display: inline-block;
        transition: all .2s ease;
        margin-left: 12px;

        path {
          transition: all .2s ease;
        }
      }
    }
  }

  .menu-mobile {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    min-height: calc((var(--vh, 1vh) * 100));
    max-height: calc((var(--vh, 1vh) * 100));
    width: 100%;
    height: 100%;

    background: linear-gradient(100deg, #5C2482 45.9%, #E53212 104.73%);
    transition: transform .2s ease;
    will-change: transform;

    transform: translateX(-100%);

    &.active {
      transform: translateX(0);
    }

    @media screen and (max-width: 1160px) {
      display: flex;
      padding: calc((var(--vh, 1vh) * 20)) 0 0 40px;
    }

    &__close {
      position: absolute;
      right: 12px;
      top: 35px;
    }

    &__links {
      display: flex;
      flex-direction: column;

      a {

        font-family: $light;
        text-decoration: none;
        font-size: 24px;
        line-height: 29px;
        /* identical to box height */

        letter-spacing: 0.02em;
        text-transform: uppercase;

        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 32px;

        &.router-link-active {
          color: white;
        }

        svg {
          display: inline-block;
          margin-left: 12px;
        }
      }
    }
  }
}
</style>