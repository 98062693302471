import {createStore} from 'vuex'

export default createStore({
  state: {
    stage: 1,
    prevStage: 1,
    navigationDirection: '',
    smallMenu: false,
    changeStage: false,
    animationProcess: true,
    mobileMenu: false,
    isWhiteBg: false,
    stage4Navigation: false,
    preloader: true,
  },
  getters: {
    getStage(state) {
      return state.stage
    },
    getAnimationProcess(state) {
      return state.animationProcess
    },
    getSmallMenu(state) {
      return state.smallMenu
    },
    getChangeStage(state) {
      return state.changeStage
    },
    getMobileMenu(state) {
      return state.mobileMenu
    },
    getIsWhiteBg(state) {
      return state.isWhiteBg
    },
    getPrevStage(state) {
      return state.prevStage
    },
    getStage4Navigation(state) {
      return state.stage4Navigation
    },
    getPreloader(state) {
      return state.preloader
    }

  },
  mutations: {
    setStage(state, stage) {

      state.prevStage = state.stage

      state.animationProcess = false

      if (state.stage < stage) {
        state.smallMenu = true
      } else if (state.stage > stage) {
        state.smallMenu = false
      }

      state.stage = stage

      setTimeout(() => {
        state.animationProcess = true
      }, 500)
    },

    setMobileMenu(state, data) {
      state.mobileMenu = data
    },

    setSmallMobile(state, data) {
      state.smallMenu = data
    },

    setWhiteBg(state, data) {
      state.isWhiteBg = data
    },

    setStage4Navigation(state, data) {
      state.stage4Navigation = data
    },
    setPreloader(state, data) {
      state.preloader = data
    }
  },
  actions: {
    ifSmallMobile({commit}, data) {
      commit('setSmallMobile', data)
    },
    goToStage({commit}, stage) {
      commit('setStage', stage)
    },
    mobileMenuSwitch({commit}, data) {
      commit('setMobileMenu', data)
    },
    switchWhiteBg({commit}, data) {
      commit('setWhiteBg', data)
    },
    switchStage4Navigation({commit}, data) {
      commit('setStage4Navigation', data)
    },
    switchPreloader({commit}, data) {
      commit('setPreloader', data)
    }
  },
  modules: {}
})
