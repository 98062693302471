<template>
  <div class="stage1-mob">

    <div class="text-wrapper">
      <div class="text-wrapper__line icon">
        <p>
          Merge
        </p>
        <img src="@/assets/images/app_icon.png" alt="">
      </div>
      <p class="text-wrapper__line">your</p>
      <p class="text-wrapper__line m-l">physical</p>
      <p class="text-wrapper__line">world with</p>
      <p class="text-wrapper__line"><span>digital</span></p>
      <p class="text-wrapper__line"><span>magic</span></p>
    </div>

  </div>
</template>

<script>

export default {
  name: "stage1-mob",

  data: () => ({
    showLogo: true

  }),

  mounted() {
    setTimeout(() => {
      this.showLogo = false
    }, 2000)
  },

  computed: {},

  methods: {}
}
</script>

<style scoped lang="scss">
@import "@/assets/style/styles";

.stage1-mob {
  min-height: calc((var(--vh, 1vh) * 100));
  position: relative;

  .text-wrapper {
    position: absolute;
    bottom: calc(142px + 38px);
    left: 20px;

    &__line {
      font-size: 56px;
      line-height: 48px;

      text-transform: uppercase;
      display: block;

      color: rgba(255, 255, 255, 0.6);
      font-family: $thin;

      &.m-l {
        margin-left: 60px;
      }

      &.icon {
        display: flex;
        align-items: center;
      }

      span {
        font-family: $ELightI;
        font-style: italic;
        color: white;
      }

      img {
        width: 42px;
        height: auto;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
}

</style>