<template>
  <div class="preloader">

    <div class="row">
      <div class="percent">
        {{ percent }}%
      </div>
      <div class="line-wrapper">
        <div class="fill" :style="{width: percent+ '%'}"></div>
      </div>
    </div>

  </div>
</template>
<script>

import bg from "@/components/svg/float-bg.vue";
import {mapActions} from "vuex";

export default {
  name: "preloader",
  components: {bg},

  data() {
    return {
      percent: 0

    }
  },

  mounted() {
    this.counter()
  },

  methods: {
    ...mapActions(['switchPreloader']),
    counter() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      let counting = setInterval(() => {
        if (this.percent < 100) {
          this.percent++
        } else {
          clearInterval(counting)
        }
      }, 15);
    }
  },
  watch: {
    percent(to, from) {
      if (to === 100) {
        if (window.matchMedia("(max-width: 1160px)").matches) {
          document.getElementsByTagName("body")[0].style.overflow = "visible";
        }
        this.switchPreloader(false)
      }
    }
  },


}
</script>


<style scoped lang="scss">
@import "@/assets/style/styles";

.preloader {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(240.64deg, #5C2482 5.77%, #E53212 100%);

  display: flex;
  align-items: flex-end;
  padding: 50px;

  @media screen and (max-width: 1440px) {
    padding: 20px;
  }

  .row {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  .percent {
    font-family: $thinI;
    font-style: italic;
    color: #FFFFFF;
    font-size: 140px;
    line-height: 120px;
    margin-right: 20px;
    max-width: 320px;
    width: 100%;

    @media screen and (max-width: 1440px) {
      font-size: 100px;
      line-height: 80px;
      max-width: 250px;
    }

    @media screen and (max-width: 768px) {
      font-size: 80px;
      line-height: 60px;
      max-width: 200px;
    }
    @media screen and (max-width: 425px) {
      font-size: 44px;
      line-height: 42px;
      max-width: 100px;
    }
  }

  .line-wrapper {
    max-width: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.2);
    height: 2px;

    .fill {
      background: white;
      width: 100%;
      height: 100%;
    }
  }

  .float-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
  }

  .svg-wrapper {
    position: absolute;
    z-index: 4;
    right: -5%;
    top: 0;
    width: 50vw;
    height: 50vh;
    transition: all .4s ease;

    transform: translate(0, 0);

  }
}

@keyframes widthFill {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>