import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/home.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/magic',
    name: 'magic',
    component: () => import('../views/magic.vue')
  }, {
    path: '/download',
    name: 'download',
    component: () => import('../views/download.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return {x: 0, y: 0}
    }
  }
})

export default router
