<template>
  <div class="stage4-mob">
    <ul>
      <li>
        <p><b>Seamless Integration </b></p>
        <p>SPEECHki offers seamless integration with popular devices and operating systems, ensuring the best possible
          AR experience across all platforms</p>
      </li>
      <li>
        <p><b>User-Friendly Interface </b></p>
        <p>Our intuitive interface makes it easy for anyone to immerse in enchanting AR experiences without any
          technical expertise</p>
      </li>
      <li>
        <p><b>Dedicated Support & Training</b></p>
        <p>Our team of experts provides dedicated support and training to help your business make the most of our AR
          platform, ensuring smooth implementation and adoption</p>
      </li>
      <li>
        <p><b>Business-Centric Solutions </b></p>
        <p>Our platform is tailored for businesses, focusing on providing AR experiences that boost productivity,
          engagement, and innovation across a range of industries</p>
      </li>
      <li>
        <p><b>Limitless Possibilities </b></p>
        <p>From education to gaming, marketing to art, the applications of SPEECHki are boundless, unlocking new
          opportunities for innovation and growth</p>
      </li>
      <li>
        <p><b>Scalable and Customizable </b></p>
        <p>From small businesses to large enterprises, our AR solutions are scalable and customizable to fit your
          specific needs and requirements</p>
      </li>
    </ul>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "stage4-mob",

  data: () => ({}),

  mounted() {
  },

  computed: {},

  methods: {}
}
</script>

<style scoped lang="scss">
@import "@/assets/style/styles";

.stage4-mob {
  padding: 0 20px;
  margin-top: 53px;

  ul {
    li {
      padding: 32px 0;
      border-top: 1px solid rgba(255, 255, 255, 0.3);

      p {
        font-size: 18px;
        line-height: 22px;

        color: rgba(255, 255, 255, 0.6);
        font-family: $light;

        b {
          font-family: $bold;
          color: white;
        }
      }
    }
  }
}

</style>