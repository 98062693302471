<template>
  <div class="stage2-mob">
    <h2>Story<br>behind<br><b>SPEECHki</b></h2>
    <p>The name <b>«SPEECHki»</b> is a fusion of «speech» and the Slavic word «iskri», which means «spark». It
      represents our
      mission to spark imagination, creativity, and innovation through the power of speech and augmented reality.</p>
    <p><b>SPEECHki AR Platform</b> - Transform Your Reality. With SPEECHki, you can now overlay 3D models, animations,
      and interactive content onto your everyday environment. Unleash your creativity, explore new horizons, and
      experience a whole new level of interaction with the world around you.</p>
  </div>
</template>

<script>

export default {
  name: "stage2-mob",

  data: () => ({}),

  mounted() {
  },

  computed: {},

  methods: {}
}
</script>

<style scoped lang="scss">
@import "@/assets/style/styles";

.stage2-mob {
  //min-height: calc((var(--vh, 1vh) * 100));

  padding: 0 20px;

  h2 {
    padding-top: 120px;
    margin-bottom: 23px;

    font-size: 56px;
    line-height: 48px;
    /* or 86% */

    text-transform: uppercase;
    font-family: $thin;

    color: rgba(255, 255, 255, 0.6);


    b {
      font-family: $lightI;
      font-style: italic;
      color: #FFFFFF;
    }
  }

  p {
    font-size: 18px;
    line-height: 22px;
    text-align: justify;

    color: rgba(255, 255, 255, 0.6);
    font-family: $light;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    b {
      color: #FFFFFF;
      font-family: $bold;
    }
  }
}

</style>