<template>
  <img src="@/assets/images/bg.png" alt="">
</template>

<script>
export default {
  name: "float-bg"
}
</script>

<style scoped lang="scss">

.bg {
  width: 2700px;
  height: auto;
  display: block;
}
</style>