<template>
  <div :class="`current-stage${getStage} home ${getIsWhiteBg}`">

    <div class="mobile-wrapper">

      <div class="fixed-bg"></div>

      <stage1Mob class="stage-mob"></stage1Mob>
      <stage2Mob class="stage-mob"></stage2Mob>
      <stage3Mob class="stage-mob"></stage3Mob>
      <stage4Mob class="stage-mob"></stage4Mob>
      <stage5Mob class="stage-mob"></stage5Mob>
    </div>

    <div class="desk-wrapper" @wheel="nextStage">
      <div :class="`svg-wrapper bg-stage${getStage}`">
        <bg class="float-bg"/>
      </div>

      <stage1
          class="stage" v-if="getStage === 1"
          :class="[{ current: getStage === 1}, { prev: getPrevStage === 2}]"/>

      <stage2
          class="stage "
          v-if="getStage === 2"
          :class="[{ current: getStage === 2 },{ next: getPrevStage === 1},{ prev: getPrevStage === 3}]"/>

      <stage3
          class="stage "
          v-if=" getStage === 3"
          :class="[{ current: getStage === 3 },{ next: getPrevStage === 2},{ prev: getPrevStage === 4}]"/>

      <stage4
          class="stage "
          v-if=" getStage === 4 "
          :class="[{ current: getStage === 4 },{ next: getPrevStage === 3},{ prev: getPrevStage === 5}]"/>

    </div>

    <floatBtn/>

  </div>
</template>

<script>

import stage1 from '@/components/page/stage1.vue'
import stage1Mob from '@/components/page/mobile/stage1.vue'
import stage2 from '@/components/page/stage2.vue'
import stage2Mob from '@/components/page/mobile/stage2.vue'
import stage3 from '@/components/page/stage3.vue'
import stage3Mob from '@/components/page/mobile/stage3.vue'
import stage4 from '@/components/page/stage4.vue'
import stage4Mob from '@/components/page/mobile/stage4.vue'
import stage5Mob from '@/components/page/mobile/stage5.vue'
import floatBtn from '@/components/navigation/float-btn.vue'
import bg from '@/components/svg/float-bg.vue'
import {mapActions, mapGetters} from "vuex";

// @ is an alias to /src

export default {
  name: 'home',
  components: {
    stage1,
    stage2,
    stage3,
    stage4,
    stage1Mob,
    stage2Mob,
    stage3Mob,
    stage4Mob,
    stage5Mob,
    bg,
    floatBtn
  },

  data: () => ({
    lastScrollTop: 0,
    trigger25: true,
  }),

  mounted() {
    gtag('event', 'load_page', {'page_name': 'home'})
    window.addEventListener("scroll", this.scrollEvents, false);
    window.scroll(0, 0)
    if (window.matchMedia("(min-width: 1161px)").matches) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
  },

  beforeUnmount() {
    this.goToStage(1)
    window.removeEventListener("scroll", this.scrollEvents, false);
  },

  watch: {
    'getStage'(to, from) {
      if (to === 4 && window.matchMedia("(min-width: 1161px)").matches) {
        setTimeout(() => {
          document.getElementsByTagName("body")[0].style.overflow = "visible";
        }, 200)
      } else if (to !== 4 && window.matchMedia("(min-width: 1161px)").matches) {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
      }
    }
  },

  methods: {
    scrollEvents() {

      let st2 = window.pageYOffset || document.documentElement.scrollTop;

      if (st2 > this.lastScrollTop) {
        if (!this.getSmallMenu) {
          this.ifSmallMobile(true)
        }
      } else if (st2 < this.lastScrollTop) {
        if (this.getSmallMenu) {
          this.ifSmallMobile(false)
        }
      }
      this.lastScrollTop = st2 <= 0 ? 0 : st2;


      var h = document.documentElement,
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';

      var percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);

      if (percent >= 25 && this.trigger25) {
        this.trigger25 = false
        gtag('event', 'scroll', {'scroll_percent': 'scroll > 25%', 'scroll_page': 'home'})
      }
    },

    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + 100
      );
    },

    ...mapActions(['ifSmallMobile', 'goToStage']),

    nextStage(e) {
      if (!this.getChangeStage && this.getAnimationProcess) {
        if (e.deltaY > 0 && this.getStage !== 4 && this.getStage !== 2) {
          this.goToStage(this.getStage + 1)
        } else if (e.deltaY < 0 && this.getStage === 3) {
          this.goToStage(1)
        } else if (e.deltaY < 0 && this.getStage !== 1 && this.getStage !== 4 && this.getStage !== 3) {
          this.goToStage(this.getStage - 1)
        } else if (e.deltaY < 0 && this.getStage !== 1 && this.getStage === 4 && this.getStage4Navigation) {
          this.goToStage(this.getStage - 1)
        }
      }
    },
  },

  computed: {
    ...mapGetters(['getIsWhiteBg', 'getStage', 'getSmallMenu', 'getChangeStage', 'getAnimationProcess', 'getPrevStage', 'getStage4Navigation'])
  }
}
</script>

<style lang="scss">
@import "@/assets/style/styles";

.home {
  overflow: hidden;
  position: relative;
  background-color: #E53212;

  transition: background-color .2s;

  &.true {
    background-color: white;

    .desk-wrapper {
      .svg-wrapper {
        display: none !important;
      }
    }
  }

  .mobile-wrapper {

    .stage-mob {
      position: relative;
      z-index: 2;
    }

    .fixed-bg {
      background: linear-gradient(219.73deg, #5C2482 25.62%, #E53212 94.7%);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    display: block;
    @media screen and (min-width: 1161px) {
      display: none !important;
    }
  }

  .desk-wrapper {
    max-width: 1800px;
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 1160px) {
      display: none !important;
    }
  }

  .stage {
    position: relative;
    z-index: 2;
  }

  .svg-wrapper {
    position: fixed;
    z-index: 0;
    right: -5%;
    top: 0;
    width: 50vw;
    height: 50vh;
    transition: transform 1s ease;


    &.bg-stage1 {
      transform: translate(0, 0);
    }

    &.bg-stage2 {
      transform: translate(-70%, -20%) rotate(-30deg);
    }

    &.bg-stage3 {
      transform: translate(-70%, -20%) rotate(-30deg);

    }

    &.bg-stage4 {
      transform: translate(-130%, 20%) rotate(-60deg);

    }

    &.bg-stage5 {
      transform: translate(-70%, -20%) rotate(-30deg);

    }
  }

  .float-bg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.stage1 {
  }

  .stage {
    display: none;

    &.current {
      display: block;
    }
  }
}

</style>