<template>
  <div class="stage3-mob">
    <h2>why<br><b>SPEECHki?</b></h2>

    <div ref="stage3Logo" class="img-wrapper">
      <img :class="[{active: animation}]" class="center-logo" src="@/assets/images/stage3-logo.png"
           alt="SPEECHki-logo">
    </div>
  </div>
</template>

<script>

export default {
  name: "stage3-mob",

  data: () => ({
    animation: false,
  }),

  mounted() {
    window.addEventListener("scroll", this.scrollEvents, false);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.scrollEvents, false);
  },

  computed: {},

  methods: {
    scrollEvents() {
      if (this.isInViewport(this.$refs.stage3Logo)) {
        this.animation = true
      } else {
        this.animation = false
      }
      if (this.isInViewport(this.$refs.stage3Logo)) {
        this.animation = true
      } else {
        this.animation = false
      }
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + 100
      );
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/styles";

.stage3-mob {
  min-height: calc((var(--vh, 1vh) * 100));

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  h2 {
    font-size: 56px;
    line-height: 48px;
    /* or 86% */

    text-align: center;
    text-transform: uppercase;

    color: rgba(255, 255, 255, 0.6);
    font-family: $thin;

    b {
      font-family: $lightI;
      font-style: italic;

      color: #FFFFFF;
    }
  }

  .img-wrapper {
    position: absolute;
    width: 261px;
    bottom: -106px;
  }

  .center-logo {

    width: 100%;
    height: auto;
    display: block;

    transition: transform .2s ease;
    will-change: transform;
    transform-origin: top center;

    &.active {
      transform: scale(.4);
    }
  }
}

</style>