<template>
  <div class="stage3">
    <div class="center-block">
      <div class="title animation-text">
        <p><span class="animation-text1">Story</span></p>
        <p><span class="animation-text2">behind</span></p>
        <p>
          <span class="bold animation-text3">SPEECHki</span>
        </p>
      </div>
      <div class="description">
        <p class="animation-text-small animation-text-small1">The name <b>«SPEECHki»</b> is a fusion of «speech» and the
          Slavic word «iskri», which means «spark». It
          represents
          our mission to spark imagination, creativity, and innovation through the power of speech and augmented
          reality.</p>
        <p class="animation-text-small animation-text-small2"><b>SPEECHki AR Platform</b> - Transform Your Reality.
          With SPEECHki, you can now overlay 3D models, animations, and interactive content onto your everyday
          environment. Unleash your creativity, explore new horizons, and experience a whole new level of interaction
          with the world around you.</p>
      </div>
    </div>
  </div>
</template>
<script>

import {gsap} from "gsap";

export default {
  name: "stage3",

  mounted() {
    this.animationText()
  },
  computed: {},
  methods: {
    animationText() {
      gsap.to('.animation-text p span', {y: '110%', opacity: 0, duration: 0})
      gsap.to('.animation-text-small', {y: 10, opacity: 0, duration: 0})
      let tl = gsap.timeline();
      tl
          .to('.animation-text1', {y: "0%", duration: .30, delay: .2, opacity: 1, ease: "back"})
          .to('.animation-text2', {y: "0%", duration: .30, opacity: 1, ease: "back"}, 0.3)
          .to('.animation-text3', {y: "0%", duration: .30, opacity: 1, ease: "back"}, 0.4)
          .to('.animation-text-small1', {y: 0, opacity: 1, duration: .30, ease: "back"}, .5)
          .to('.animation-text-small2', {y: 0, opacity: 1, duration: .30, ease: "back"}, .6)

    }
  }
}
</script>

<style scoped lang="scss">
.stage3 {
  @import "@/assets/style/styles";


  min-height: calc((var(--vh, 1vh) * 100));
  max-height: calc((var(--vh, 1vh) * 100));
  height: 100%;
  width: 100%;

  .center-block {
    max-width: calc(1361px + 40px);

    width: 100%;
    margin: 0 auto 0 auto;
    padding: 190px 20px 0 20px;

    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .title {
      font-family: $thin;
      font-size: 140px;
      line-height: 120px;

      text-transform: uppercase;

      color: rgba(255, 255, 255, 0.6);
      margin-right: 15px;

      @media screen and (max-width: 1200px) {
        font-size: 120px;
        line-height: 110px;
      }

      span {
        display: inline-block;
        overflow: hidden;
      }

      .bold {
        font-family: $lightI;
        font-style: italic;
        font-weight: 300;
        color: white;
      }
    }

    .description {
      margin-left: 15px;
      margin-top: 28vh;

      max-width: 550px;
      width: 100%;


      p {
        margin-bottom: 32px;
        font-family: $light;
        font-size: 24px;
        line-height: 29px;
        text-align: justify;

        color: rgba(255, 255, 255, 0.6);

        @media screen and (max-width: 1200px) {
          font-size: 22px;
          line-height: 26px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        b {
          color: white;
          font-family: $bold;
          font-weight: 700;
        }
      }
    }
  }
}

</style>