<template>
  <div class="container" :class="[{ mob: mobile }, { desk: !mobile }]">
    <menuBlock/>
    <Transition>
      <preloader v-if="getPreloader"></preloader>
    </Transition>
    <router-view/>

  </div>
</template>
<script>

import menuBlock from "@/components/navigation/menu.vue"
import {mapActions, mapGetters} from "vuex";
import preloader from "@/components/preloader.vue";

export default {
  name: 'app',

  data: () => ({
    mobile: false
  }),
  components: {
    preloader,
    menuBlock
  },

  computed: {
    ...mapGetters(['getIsWhiteBg', 'getPreloader'])
  },


  methods: {

    ...mapActions(['switchWhiteBg']),

    updateVH() {
      if (window.matchMedia("(min-width: 1161px)").matches) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }
    },
  },
  mounted() {
    this.updateVH();

    window.addEventListener("resize", this.updateVH);

    this.$nextTick(() => {
      let ua = navigator.userAgent.toLowerCase();
      let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
      let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

      if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        this.mobile = true
      }
      if (navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 2 &&
          /MacIntel/.test(navigator.platform)) {
        this.mobile = true
      }

      if (isAndroid || iOS === true) {
        this.mobile = true

      }
    })
  },

};
</script>
<style lang="scss">
@import "@/assets/style/styles";

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

#app {
  > div {
    &.mob {

      .slider-btn {
        -webkit-tap-highlight-color: rgba(255, 0, 0, 0) !important;
        outline: none !important;
        cursor: none !important;

        &:focus, &:active {
          background: rgba(255, 255, 255, 0) !important;
          outline: none !important;
          cursor: none !important;
        }
      }

      * {
        -webkit-tap-highlight-color: rgba(255, 0, 0, 0) !important;
        outline: none !important;
        cursor: none !important;
      }
    }
  }
}
</style>
